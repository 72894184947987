<template>
  <v-row class='ma-5 pa-0'>
    <v-col
      v-for='box in reportingNumbers'
      :key='box.icon'
      class='pa-0 mr-5'
      cols='4'
    >
      <v-card
        :loading='loading'
        class='d-flex flex-row elevation-3 rounded-lg'
        height='140px'
      >
        <v-icon class='primary white--text px-2 rounded-lg' size='60'>
          {{ box.icon }}
        </v-icon>
        <v-col class='pa-5 d-flex flex-column justify-center'>
          <v-card-title
            v-for='item in box.data'
            :key='item.text'
            class='pa-0 ma-0 font-weight-bold'
          >
            {{ item.number || 0 }}{{ item.text }}
          </v-card-title>
        </v-col>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'ReportingNumbers',
  props: {
    reportingNumbers: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped>

</style>