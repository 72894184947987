var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-5 ma-5 elevation-3 rounded-lg"},[_c('v-card-title',{staticClass:"font-weight-bold pa-0 mb-4"},[_vm._v(_vm._s(_vm.sections.red_flags || 'Red flags title'))]),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.redFlags,"loading":_vm.loading || _vm.isTableLoading,"options":_vm.tableOptions,"server-items-length":_vm.tableTotalItems,"items-per-page":10,"loading-text":"Please wait... Red flags are loading","must-sort":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.registered",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mr-5"},[(item.flags.includes('1'))?_c('span',[_vm._v("YES")]):_c('span',[_vm._v("NO")])])]}},{key:"item.scheduled",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mr-5"},[(item.flags.includes('2'))?_c('span',[_vm._v("YES")]):_c('span',[_vm._v("NO")])])]}},{key:"item.voted",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mr-5"},[(item.flags.includes('3'))?_c('span',[_vm._v("YES")]):_c('span',[_vm._v("NO")])])]}},{key:"item.missed_scheduled",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mr-5"},[(item.flags.includes('4'))?_c('span',[_vm._v("YES")]):_c('span',[_vm._v("NO")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }