<template>
  <div v-frag>
    <ChangeElection
      :elections='elections'
      :selectedElection='selectedEl'
      :title='sections.change_election_reporting'
    />
    <ReportingNumbers
      :loading='false'
      :reportingNumbers='comparedReportingNumbers'
    />
    <RedFlags
      :redFlags='flags'
      :update='updateTable'
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import ReportingNumbers from '../components/ReportingPage/ReportingNumbers'
import RedFlags from '../components/ReportingPage/RedFlags'
import ChangeElection from '../components/change-election/change-election'

export default {
  name: 'ReportingPage',
  components: { ChangeElection, RedFlags, ReportingNumbers },
  data() {
    return {
      updateTable: false
    }
  },
  computed: {
    ...mapState({
      elections: s => s.electionStore.userElections,
      selectedEl: s => s.electionStore.selectedElection,
      flags: s => s.reportingStore.redFlags,
      sections: s => s.cmsStore.sections
    }),
    ...mapGetters('reportingStore', ['comparedReportingNumbers'])
  },
  watch: {
    selectedEl: {
      handler() {
        this.updateTable = !this.updateTable
      },
      deep: true
    }
  },
  async created() {
    await this.getUserElections()
  },
  methods: {
    ...mapActions({
      getUserElections: 'electionStore/getUserElections'
    })
  }
}
</script>
