<template>
  <v-card class='pa-5 ma-5 elevation-3 rounded-lg'>
    <v-card-title class='font-weight-bold pa-0 mb-4'>{{ sections.red_flags || 'Red flags title' }}</v-card-title>
    <v-data-table
      :headers='tableHeaders'
      :items='redFlags'
      :loading='loading || isTableLoading'
      :options.sync='tableOptions'
      :server-items-length='tableTotalItems'
      :items-per-page='10'
      loading-text='Please wait... Red flags are loading'
      must-sort
    >
      <template #item.registered='{item}'>
        <div class='mr-5'>
          <span v-if="item.flags.includes('1')">YES</span>
          <span v-else>NO</span>
        </div>
      </template>
      <template #item.scheduled='{item}'>
        <div class='mr-5'>
          <span v-if="item.flags.includes('2')">YES</span>
          <span v-else>NO</span>
        </div>
      </template>
      <template #item.voted='{item}'>
        <div class='mr-5'>
          <span v-if="item.flags.includes('3')">YES</span>
          <span v-else>NO</span>
        </div>
      </template>
      <template #item.missed_scheduled='{item}'>
        <div class='mr-5'>
          <span v-if="item.flags.includes('4')">YES</span>
          <span v-else>NO</span>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'RedFlags',
  props: {
    redFlags: {
      type: Array,
      required: true,
      default: () => []
    },
    loading: {
      type: Boolean
    },
    update: {
      type: Boolean
    }
  },
  data() {
    return {
      tableOptions: {},
      tableTotalItems: 0,
      isTableLoading: false,
      tableHeaders: [
        { text: 'First Name', value: 'first_name' },
        { text: 'Last Name', value: 'last_name' },
        { text: 'Email', value: 'email' },
        { text: 'Reports To', value: 'reports_to' },
        { text: 'Registered', value: 'registered', align: 'center', sortable: false },
        { text: 'Scheduled', value: 'scheduled', align: 'center', sortable: false },
        { text: 'Voted', value: 'voted', align: 'center', sortable: false },
        { text: 'Missed Scheduled Voting Date', value: 'missed_scheduled', align: 'center', sortable: false }
      ]
    }
  },
  computed: {
    ...mapState('cmsStore', ['sections'])
  },
  watch: {
    tableOptions: {
      async handler(newVal, oldVal) {
        if (Object.keys(oldVal).length)
          await this.getRedFlags()
      },
      deep: true
    },
    async update() {
      await this.getRedFlags()
    }
  },
  methods: {
    ...mapActions({
      getReportingInformation: 'reportingStore/getReportingInformation'
    }),
    async getRedFlags() {
      this.isTableLoading = true
      const { itemsPerPage, page, sortBy, sortDesc } = this.tableOptions
      const params = {
        page,
        per_page: itemsPerPage > 0 ? itemsPerPage : 'all',
        sort_by: sortBy ? sortBy[0] : null,
        order: sortBy[0] ? (sortDesc[0] ? 'desc' : 'asc') : null
      }
      const { total } = await this.getReportingInformation(params)
      this.tableTotalItems = total
      this.isTableLoading = false
    }
  }
}
</script>
